import React from "react";
import header1 from "../resources/images/homepage/header1.png";

import auction1 from "../resources/images/homepage/auction1.png";
import auction2 from "../resources/images/homepage/auction2.png";
import auction3 from "../resources/images/homepage/aution3.png";
import auction4 from "../resources/images/homepage/auction4.png";
import auction5 from "../resources/images/homepage/auction5.png";
import auction6 from "../resources/images/homepage/auction6.png";
import auction7 from "../resources/images/homepage/auction7.png";
import auction8 from "../resources/images/homepage/auction8.png";
import auction9 from "../resources/images/homepage/auction9.png";
import auction10 from "../resources/images/homepage/auction10.png";
import auction11 from "../resources/images/homepage/auction11.png";
import auction12 from "../resources/images/homepage/auction12.mov";
import auction13 from "../resources/images/homepage/auction13.mov";
import auction14 from "../resources/images/homepage/auction14.png";
import auction15 from "../resources/images/homepage/auction15.png";
import auction16 from "../resources/images/homepage/auction16.mov";
import auction17 from "../resources/images/homepage/auction17.png";

import user1 from "../resources/images/homepage/user1.jpeg";
import user2 from "../resources/images/homepage/user2.jpeg";
import user3 from "../resources/images/homepage/user3.jpeg";
import user4 from "../resources/images/homepage/user4.jpeg";
import user5 from "../resources/images/homepage/user5.jpeg";
import user6 from "../resources/images/homepage/user6.jpeg";
import user7 from "../resources/images/homepage/user7.jpeg";
import user8 from "../resources/images/homepage/user8.jpeg";
import user9 from "../resources/images/homepage/user9.jpeg";
import user10 from "../resources/images/homepage/user10.jpeg";
import user11 from "../resources/images/homepage/user11.jpeg";
import user12 from "../resources/images/homepage/user12.jpeg";
import user13 from "../resources/images/homepage/user13.jpeg";

import creation1 from "../resources/images/homepage/creation1.jpeg";
import creation2 from "../resources/images/homepage/creation2.jpeg";
import creation3 from "../resources/images/homepage/creation3.jpeg";
import creation4 from "../resources/images/homepage/creation4.jpeg";
import creation5 from "../resources/images/homepage/creation5.jpeg";
import creation6 from "../resources/images/homepage/creation6.jpeg";
import creation7 from "../resources/images/homepage/creation7.jpeg";

import artwork1 from "../resources/images/homepage/artwork1.png";
import artwork2 from "../resources/images/homepage/artwork2.png";
import artwork3 from "../resources/images/homepage/artwork3.png";
import artwork4 from "../resources/images/homepage/artwork4.png";
import artwork5 from "../resources/images/homepage/artwork5.png";
import artwork6 from "../resources/images/homepage/artwork6.png";
import artwork7 from "../resources/images/homepage/artwork7.mov";
import artwork8 from "../resources/images/homepage/artwork8.png";
import artwork9 from "../resources/images/homepage/artwork9.png";
import artwork10 from "../resources/images/homepage/artwork10.mov";

import one from "../resources/images/explore/1.png";
import two from "../resources/images/explore/2.png";
import three from "../resources/images/explore/3.png";
import e4 from "../resources/images/explore/4.png";
import e5 from "../resources/images/explore/5.mov";
import e6 from "../resources/images/explore/6.mov";
import e7 from "../resources/images/explore/7.png";
import e8 from "../resources/images/explore/8.png";
import e9 from "../resources/images/explore/9.png";
import e10 from "../resources/images/explore/10.png";

export const auction = [
  {
    content: auction1,
    type: "image",
    title: " the-walking-dead-lands-tools-series-2",
    bid: "3.0930ETH",
  },
  {
    content: auction2,
    type: "image",
    title: "firstproject",
    bid: "3.0950ETH",
  },
  {
    content: auction3,
    type: "image",
    title: "colours-of-our-unique-life",
    bid: "3.782ETH",
  },
  {
    content: auction4,
    type: "image",
    title: "fire-onisun",
    bid: "3.572ETH",
  },
  {
    content: auction5,
    type: "image",
    title: "water-onisum",
    bid: "4.272ETH",
  },
  {
    content: auction6,
    type: "image",
    title: "metal-onisum",
    bid: "4.272ETH",
  },
  {
    content: auction7,
    type: "image",
    title: "create ear",
    bid: "3.782ETH",
  },
  {
    content: auction8,
    type: "image",
    title: "metaverse undeads",
    bid: "3.572ETH",
  },
  {
    content: auction9,
    type: "image",
    title: "transender",
    bid: "4.272ETH",
  },
  {
    content: auction10,
    type: "image",
    title: "monkey smug",
    bid: "4.272ETH",
  },
  {
    content: auction11,
    type: "image",
    title: "a-girl-14",
    bid: "4.121ETH",
  },
  {
    content: auction12,
    type: "video",
    title: "555art",
    bid: "4.346 ETH",
  },
  {
    content: auction13,
    type: "image",
    title: "brawler beartz",
    bid: "4.272ETH",
  },
  {
    content: auction14,
    type: "image",
    title: "gotta get gurt",
    bid: "3.782ETH",
  },
  {
    content: auction15,
    type: "image",
    title: "scarce dollar",
    bid: "3.572ETH",
  },
  {
    content: auction16,
    type: "image",
    title: "transender",
    bid: "4.272ETH",
  },
  {
    content: auction17,
    type: "image",
    title: "the108circle",
    bid: "4.272ETH",
  },
];
export const creators = [
  {
    content: user1,
    title: "Meta Boss",
    price: "0.25 ETH",
    type: "image",
    name: "Ahmed Zul",
  },
  {
    content: user2,
    title: "The Lucky Being",
    price: "4.10 ETH",
    type: "image",
    name: "Usman Ansari",
  },
  { content: user3, title: "Artist of Now", price: "1.50 ETH", type: "image" },
  { content: user4, title: "Biggest Bang", price: "3.30 ETH", type: "image" },
  { content: user5, title: "Usman Ansari", price: "4.34 ETH", type: "image" },
  {
    content: user6,
    title: "King Kong",
    price: "5.50 ETH",
    type: "image",
  },
  {
    content: user6,
    title: "Craftold",
    price: "0.6 ETH",
    type: "image",
    name: "metaboss",
  },
  { content: user7, title: "Meow Bits", price: "6.30 ETH", type: "image" },
  { content: user6, title: "Wow Creates", price: "2.35 ETH", type: "image" },
  { content: user8, title: "Ding Dong", price: "2.00 ETH", type: "image" },
  {
    content: user9,
    title: "Sample Best",
    price: "4.35 ETH",
    type: "image",
    name: "Andrew Flow",
  },
  { content: user10, title: "League", price: "3.30 ETH", type: "image" },
  {
    content: user11,
    title: "Title Glue",
    price: "5.35 ETH",
    type: "image",
    name: "Peter K.",
  },
  {
    content: user12,
    title: "Fisher Stack",
    price: "1.05 ETH",
    type: "image",
    name: "King Kong",
  },

  {
    content: user13,
    title: "Slipe Maker",
    price: "2.93 ETH",
    type: "image",
    name: "Craftold",
  },
];

export const creations = [
  {
    title: "wow! That Brain is Floating",
    name: creators[1 + 1].name,
    img1: creation1,
    img2: creators[1 + 1].content,
  },
  {
    title: "The Beyblade in Black",
    name: creators[2 + 1].name,
    img1: creation2,
    img2: creators[2 + 1].content,
  },
  {
    title: "Ooops! Floating Plates",
    name: creators[11 + 1].name,
    img1: creation3,
    img2: creators[11 + 1].content,
  },
  {
    title: "The Beyblade in Black",
    name: creators[9 + 1].name,
    img1: creation4,
    img2: creators[9 + 1].content,
  },

  {
    title: "My Yummy Plastic Omlet",
    name: creators[12 + 1].name,
    img1: creation5,
    img2: creators[12 + 1].content,
  },

  {
    title: "The White Sand Storm",
    name: creators[13 + 1].name,
    img1: creation6,
    img2: creators[13 + 1].content,
  },

  {
    title: "Cubic and Planic Unverse",
    name: creators[13 + 1].name,
    img1: creation7,
    img2: creators[13 + 1].content,
  },
];

export const artwork = [
  {
    content: artwork1,
    type: "image",
    title: "the-degenaissance",
    bid: "3.748 ETH",
  },
  {
    content: artwork2,
    type: "image",
    title: "a0k1verse-passport",
    bid: "4.155 ETH",
  },
  {
    content: artwork3,
    type: "image",
    title: "sougen-builder-pioneer-pass ",
    bid: "4.396 ETH",
  },
  {
    content: artwork4,
    type: "image",
    title: "distance-by-willaim",
    bid: "3.732 ETH",
  },
  {
    content: artwork5,
    type: "image",
    title: "ferrydate",
    bid: "3.624 ETH",
  },
  {
    content: artwork6,
    type: "image",
    title: "wongoods",
    bid: "4.414 ETH",
  },
  {
    content: artwork7,
    type: "image",
    title: "lascauxfuture",
    bid: "4.011 ETH",
  },
  {
    content: artwork8,
    type: "image",
    title: "stomesbyanthony",
    bid: "4.493 ETH",
  },
  {
    content: artwork9,
    type: "image",
    title: "yamabushi-s-horizons",
    bid: "3.631 ETH",
  },
  {
    content: artwork10,
    type: "image",
    title: "nounish-punks-official",
    bid: "4.302 ETH",
  },
];
export const header_card = [
  {
    content: header1,
    type: "image",
  },
];
export const explores = [
  {
    content: one,
    type: "image",
    title: "anne-spalter-ai-spaceships ",
    bid: "3.712 ETH",
  },
  {
    content: two,
    type: "image",
    title: "r3nlt-genr5 ",
    bid: "3.712 ETH",
  },
  {
    content: three,
    type: "image",
    title: "general tom",
    bid: "3.712 ETH",
  },
  {
    content: e4,
    type: "image",
    title: "spooponsound",
    bid: "3.599 ETH",
  },
  {
    content: e5,
    type: "video",
    title: "minimenclub",
    bid: "3.99 ETH",
  },
  {
    content: e6,
    type: "video",
    title: "keep safe-track",
    bid: "4.21 ETH",
  },
  {
    content: e7,
    type: "image",
    title: "joy-hoppers",
    bid: "3.518 ETH",
  },
  {
    content: e8,
    type: "image",
    title: "cripocato-1",
    bid: "3.599 ETH",
  },
  {
    content: e9,
    type: "image",
    title: "bugatti-group-phygital",
    bid: "3.626 ETH",
  },
  {
    content: e10,
    type: "image",
    title: "garbage-bags-official",
    bid: "3.664 ETH",
  },
];
