import i1 from "../resources/images/dashboard/index/1.avif";
import i2 from "../resources/images/dashboard/index/2.jpg.avif";
import i3 from "../resources/images/dashboard/index/3.avif";
import i4 from "../resources/images/dashboard/index/4.avif";
import i5 from "../resources/images/dashboard/index/5.jpg.avif";
import i6 from "../resources/images/dashboard/index/6.avif";
import i7 from "../resources/images/dashboard/index/7.jpg.avif";
import i8 from "../resources/images/dashboard/index/8.jpg.avif";
import i9 from "../resources/images/dashboard/index/9.png.avif";
import i10 from "../resources/images/dashboard/index/10.avif";
import i11 from "../resources/images/dashboard/index/11.jpg.avif";
import i12 from "../resources/images/dashboard/index/12.png";
import i13 from "../resources/images/dashboard/index/13.png.avif";
import i14 from "../resources/images/dashboard/index/14.jpg.avif";
import i15 from "../resources/images/dashboard/index/15.jpg.avif";
import i16 from "../resources/images/dashboard/index/16.jpg.avif";
import i17 from "../resources/images/dashboard/index/17.jpg.avif";
import i18 from "../resources/images/dashboard/index/18.avif";

export const datas = [
  {
    contact_address: "0x2e80177d429a3e826f417538aa7a48ecd390922f",
    name: "The Constant Fella, 1983-2023 Erratic Behavior Guys Under Zero Authority Supervision (feat",
    nft: null,
    market_cap: 0,
    floor_price: 0.005579,
    total_minted: 317,
    total_volume: 0.9573800000000003,
    NOW: 317,
  },
  {
    contact_address: "0xbb33585d2cacba9bf541aa545aa94511e0ad793c",
    name: "Trippyland",
    nft: null,
    market_cap: 0,
    floor_price: 0.05,
    total_minted: 109,
    total_volume: 1.0957,
    NOW: 109,
  },
  {
    contact_address: "0xc9a51f72e2fe2fb74ccccd760b929019f680467a",
    name: "The Bobo Council",
    nft: null,
    market_cap: 117.73393971428574,
    floor_price: 0.055,
    total_minted: 567,
    total_volume: 187.15280566963696,
    NOW: 567,
  },
  {
    contact_address: "0xf54cc94f1f2f5de012b6aa51f1e7ebdc43ef5afc",
    name: "Project NANOPASS",
    nft: i1,
    market_cap: 40.9653475,
    floor_price: 0.007999,
    total_minted: 3250,
    total_volume: 18241.434775585545,
    NOW: 3250,
  },
  {
    contact_address: "0xde69b08ff2eae4a61498b481007ad5cb88cad20d",
    name: "OFFICIAL FOMO NOMO (Discontinued)",
    nft: i2,
    market_cap: 40.9653475,
    floor_price: 0.007999,
    total_minted: 3250,
    total_volume: 18241.434775585545,
    NOW: 3250,
  },
  {
    contact_address: "0xabf66ca534f8a5081303e3873f0f4771c67b7b45",
    name: "The Walking Dead Official",
    nft: i3,
    market_cap: 0,
    floor_price: 0.012799,
    total_minted: 2126,
    total_volume: 3756.142352510373,
    NOW: 2126,
  },
  {
    contact_address: "0xb92b8d7e45c0f197a8236c8345b86765250baf7c",
    name: "Asprey Bugatti La Voiture Noire Collection",
    nft: i4,
    market_cap: 0,
    floor_price: 0.549999,
    total_minted: 191,
    total_volume: 922.1576989900002,
    NOW: 191,
  },
  {
    contact_address: "0x6110d61dd1133b0f845f1025d6678cd22a11a2fe",
    name: "Prologue by Spice",
    nft: i5,
    market_cap: 0,
    floor_price: 0.049999,
    total_minted: 343,
    total_volume: 132.46140287988894,
    NOW: 356,
  },

  {
    contact_address: "0x68f4ba8018216542ac2ab8125166be66304dd71c",
    name: "Dolce&Gabbana: DGFamily Glass Box",
    nft: i6,
    market_cap: 0,
    floor_price: 0.088849,
    total_minted: 675,
    total_volume: 3876.273059346687,
    NOW: 434,
  },

  {
    contact_address: "0x07ba6bbbb5b2dc3ec1c2b583de4d534dbb030c9f",
    name: "LimeWire Originals",
    nft: i7,
    market_cap: 53.7876,
    floor_price: 0.02,
    total_minted: 270,
    total_volume: 36.03428398999977,
    NOW: 270,
  },

  {
    contact_address: "0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270",
    name: "Bent by ippsketch",
    nft: i8,
    market_cap: 0,
    floor_price: 0.2325,
    total_minted: 567,
    total_volume: 2071.9940615290698,
    NOW: 567,
  },

  {
    contact_address: "0xc50b06f4444c5a795f1246d9416adffc178033fb",
    name: "uglyduckwtf",
    nft: i9,
    market_cap: 0,
    floor_price: 0.0017,
    total_minted: 1857,
    total_volume: 572.8065784256967,
    NOW: 1857,
  },

  {
    contact_address: "0x2b656d331607e2bd6f75b6af309d0ad76f8ff03b",
    name: "Decal by Rata",
    nft: i9,
    market_cap: 0,
    floor_price: 0.0017,
    total_minted: 1857,
    total_volume: 572.8065784256967,
    NOW: 1857,
  },

  {
    contact_address: "0x18c9ba7796d1c0d561e9ffba2e687b68bd0c7aae",
    name: "SYKY Keystones",
    nft: i10,
    market_cap: 0,
    floor_price: 0.05495,
    total_minted: 752,
    total_volume: 107.23725800000011,
    NOW: 752,
  },

  {
    contact_address: "0x27cc171b16fdd2224e1f93ed7dccfcc6790ca4bf",
    name: "Starlink PixelNauts",
    nft: i11,
    market_cap: 64.6225,
    floor_price: 0.0068,
    total_minted: 1764,
    total_volume: 676.6909618242566,
    NOW: 1764,
  },

  {
    contact_address: "0x50b45ebed0d68f4483932923e08808b3e98dabaa",
    name: "EXOHAPE OG",
    nft: i12,
    market_cap: 64.6225,
    floor_price: 0.015,
    total_minted: 1764,
    total_volume: 710.9451963925452,
    NOW: 3695,
  },

  {
    contact_address: "0x37c8896198b623cfd0d22c686a861a483edc6e4c",
    name: "Llamaverse Punks",
    nft: i13,
    market_cap: 0,
    floor_price: 0.4,
    total_minted: 449,
    total_volume: 180.54914045061804,
    NOW: 449,
  },

  {
    contact_address: "0x37c8896198b623cfd0d22c686a861a483edc6e4c",
    name: "Web3Adventure",
    nft: i14,
    market_cap: 5.477399999999999,
    floor_price: 0.059999,
    total_minted: 226,
    total_volume: 7.574385307999898,
    NOW: 226,
  },

  {
    contact_address: "0x5bcdc405cb237996d29fe3ec779209e65ff2d39c",
    name: "Love Bears",
    nft: i15,
    market_cap: 0,
    floor_price: 0.01,
    total_minted: 0.059999,
    total_volume: 7.574385307999898,
    NOW: 226,
  },

  {
    contact_address: "0x37c8896198b623cfd0d22c686a861a483edc6e4c",
    name: "Llamaverse Punks",
    nft: i16,
    market_cap: 0,
    floor_price: 0.4,
    total_minted: 449,
    total_volume: 180.54914045061804,
    NOW: 449,
  },

  {
    contact_address: "0x37c8896198b623cfd0d22c686a861a483edc6e4c",
    name: "Web3Adventure",
    nft: i17,
    market_cap: 5.477399999999999,
    floor_price: 0.059999,
    total_minted: 226,
    total_volume: 7.574385307999898,
    NOW: 226,
  },

  {
    contact_address: "0x5bcdc405cb237996d29fe3ec779209e65ff2d39c",
    name: "Love Bears",
    nft: i18,
    market_cap: 0,
    floor_price: 0.01,
    total_minted: 0.059999,
    total_volume: 7.574385307999898,
    NOW: 226,
  },
];
