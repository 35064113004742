// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBwhdD-xtUsq8nAvkZ9BKAqnPwzMPNjNs",
  authDomain: "block-art-nft.firebaseapp.com",
  projectId: "block-art-nft",
  storageBucket: "block-art-nft.appspot.com",
  messagingSenderId: "619389856266",
  appId: "1:619389856266:web:e99fec8fd8cd3eeffd4df3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
