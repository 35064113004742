import React from "react";

export default function InputSubmit() {
  return (
    <input
      type="submit"
      value="Subscribe Now"
      className="p-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  );
}
