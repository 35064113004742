import one from "../resources/images/dashboard/minted/Screenshot 2024-06-02 at 23.36.38.png";

export const datas = [
  {
    id: 0,
    creator: "luckque-iii",
    price: 3.982,
    content: one,
    type: "image",
    des: "",
    created_at: "",
  },
  {
    id: 2,
    creator: "luckque-iii",
    price: 3.982,
    content: one,
    type: "image",
    des: "",
    created_at: "",
  },
  {
    id: 3,
    creator: "luckque-iii",
    price: 3.982,
    content: one,
    type: "image",
    des: "",
    created_at: "",
  },
];
